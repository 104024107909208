<template>
    <div
        class="grow-0 sm:rounded-lg overflow-hidden shadow-lg shadow-black/5 flex m-auto max-w-screen-md w-full max-sm:bg-white max-sm:absolute max-sm:inset-0"
    >
        <button
            aria-label="Menü schließen"
            class="absolute size-9 bg-gray-100/70 hover:bg-gray-100 rounded-full top-4 right-4 flex text-gray-500 duration-100 sm:hidden"
            @click="closeModal"
        >
            <Icon name="close" class="m-auto" :size="22" />
        </button>

        <NuxtImg
            width="380"
            height="500"
            src="/images/login.jpg"
            alt="Fahrzeuge"
            sizes="0 md:380px"
            class="h-full bg-stone-400 object-cover object-center w-1/2 hidden md:block"
        />

        <FormWrapper
            v-if="!success"
            ref="form"
            :keypress-enter="submitForm"
            autocomplete="false"
            class="grid gap-4 md:w-1/2 w-full self-center py-20 px-12 bg-white sm:h-full"
        >
            <FrontpageHeadline level="4">Passwort anfordern</FrontpageHeadline>

            <p class="text-gray-600 text-sm text-start">
                Geben Sie Ihre registrierte E-Mail-Adresse ein. Wir senden Ihnen einen Link, mit dem Sie Ihr Passwort
                zurücksetzen können.
            </p>

            <FormInput
                v-model="formData.email"
                required
                focus
                name="email"
                label="E-Mail"
                placeholder="E-Mail"
                type="email"
            />

            <FrontpageButton :loading="loading" class="mt-4 w-full h-11" label="Absenden" @click="submitForm" />
        </FormWrapper>

        <div v-else class="flex flex-col justify-center items-center text-center p-12 bg-white w-full h-full">
            <h2 class="text-lg font-semibold text-green-600">E-Mail gesendet!</h2>
            <p class="text-gray-600 mt-2">
                Bitte überprüfen Sie Ihr E-Mail-Postfach für den Link zum Zurücksetzen Ihres Passworts.
            </p>
            <FrontpageButton class="mt-4" label="Schließen" @click="closeModal" />
        </div>
    </div>
</template>
<script setup>
const { layout } = useStore();

const form = ref(null);
const loading = ref(false);
const success = ref(false);
const formData = reactive({
    email: '',
});

const closeModal = () => {
    layout.value.forgotPassword = false;
    success.value = false;
};

const submitForm = async () => {
    loading.value = true;

    try {
        await $fetch('/api/account/password/renew', {
            headers: useRequestHeaders(),
            method: 'POST',
            body: {
                form: {
                    email: formData.email,
                },
            },
        });

        loading.value = false;
        success.value = true;
    } catch (error) {
        loading.value = false;
        form.value.handleErrors(error.data.statusMessage);
    }
};

onUnmounted(() => {
    layout.value.forgotPassword = false;
});
</script>

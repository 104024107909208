<template>
    <div class="mt-4 space-y-4">
        <div v-for="consent in availableConsents" :key="consent.id" class="flex items-start">
            <div class="flex h-5 items-center">
                <FormCheckbox
                    :model-value="getConsentById(consent.id)"
                    :disabled="consent.required"
                    @update:model-value="toggleConsent(consent.id, $event)"
                />
            </div>
            <div class="ml-3 text-sm">
                <label class="font-medium text-gray-700">{{ consent.name }}</label>
                <FrontpageEditorContent>
                    <div class="text-gray-500" v-html="consent.description"></div>
                </FrontpageEditorContent>
            </div>
        </div>
        <div class="mt-5 sm:mt-6 grid gap-4 sm:grid-cols-2">
            <FrontpageButton size="small" outline class="w-full sm:w-auto px-0" @click="emit('back')"
                >Zurück</FrontpageButton
            >
            <FrontpageButton size="small" class="w-full sm:w-auto px-0" @click="emit('accept')"
                >Auswahl Speichern</FrontpageButton
            >
        </div>
    </div>
</template>

<script setup>
const emit = defineEmits(['back', 'accept']);

const { availableConsents, getConsentById, toggleConsent } = useConsent();
</script>

<template>
    <Modal :model-value="showBanner" :closable="false">
        <div
            class="row-0 sm:rounded-lg bg-white overflow-hidden shadow-lg shadow-black/5 flex m-auto max-w-screen-sm w-full max-sm:bg-white max-sm:absolute max-sm:inset-0"
        >
            <div class="px-4 py-5 sm:p-6">
                <div class="sm:flex sm:items-center">
                    <div
                        class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10"
                    >
                        <Icon name="cookie" class="text-blue-600" />
                    </div>

                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <h3 class="text-lg font-medium leading-6 text-gray-900">Cookie Einstellungen</h3>
                        <p v-if="requiredConsents.length > 0 && showSettings" class="text-sm text-gray-500">
                            Bestimmte Cookies sind erforderlich, um diese Funktionalität zu nutzen:
                            <span class="font-semibold">{{ requiredConsents.join(', ') }}</span
                            >. Bitte akzeptieren Sie die notwendigen Cookies, um fortzufahren.
                        </p>
                        <p v-else class="text-sm text-gray-500">
                            Wir verwenden Cookies, um Ihr Erlebnis zu verbessern. Wählen Sie Ihre Einstellungen unten aus.
                        </p>
                    </div>
                </div>

                <ConsentDetails
                    v-if="!showSettings"
                    @reject="submitOnlyRequiredCookies"
                    @accept="submitAllAvailableConsents"
                />
                <ConsentSettings v-else @back="toggleSettings" @accept="submitSelectedConsents" />
                <div
                    v-if="!showSettings"
                    class="mt-4 flex flex-col sm:flex-row gap-3 text-xs text-gray-500 text-center sm:justify-between"
                >
                    <button class="hover:underline" @click="toggleSettings">Einstellungen</button>
                    <span class="hidden sm:inline border-l border-gray-300 h-4 self-center"></span>
                    <NuxtLink to="/impressum" class="hover:underline">Impressum</NuxtLink>
                    <span class="hidden sm:inline border-l border-gray-300 h-4 self-center"></span>
                    <NuxtLink to="/datenschutz" class="hover:underline">Datenschutz</NuxtLink>
                    <span class="hidden sm:inline border-l border-gray-300 h-4 self-center"></span>
                    <NuxtLink to="/nutzungsbedingungen" class="hover:underline">Nutzungsbedingungen</NuxtLink>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script setup>
const {
    consentPresent,
    isCurrentPageExcluded,
    requiredConsentSlugs,
    submittedConsents,
    submitAllAvailableConsents,
    submitOnlyRequiredCookies,
    submitSelectedConsents,
    isRevalidationExpired,
} = useConsent();

const showBanner = computed(() => {
    if (isCurrentPageExcluded.value) return false;
    return !consentPresent.value || isRevalidationExpired() || requiredConsentSlugs?.value?.length > 0;
});

const requiredConsents = computed(() => {
    return requiredConsentSlugs.value.filter((slug) => submittedConsents.value.some((c) => c.slug !== slug));
});

const showSettings = ref(requiredConsents?.value?.length > 0 || false);

const toggleSettings = () => {
    showSettings.value = !showSettings.value;
};
</script>

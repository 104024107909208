<template>
    <div
        class="grow-0 sm:rounded-lg overflow-hidden shadow-lg shadow-black/10 flex m-auto max-w-screen-md w-full max-sm:bg-white max-sm:absolute max-sm:inset-0 relative"
    >
        <button
            aria-label="Modal schließen"
            class="absolute size-9 bg-gray-100/70 hover:bg-gray-200 rounded-full top-4 right-4 flex text-gray-500 duration-100 sm:hidden"
            @click="layout.authGate = false"
        >
            <Icon name="close" class="m-auto" :size="22" />
        </button>

        <NuxtImg
            width="380"
            height="500"
            src="/images/login.jpg"
            alt="Fahrzeuge"
            class="hidden md:block w-1/2 object-cover object-center"
        />

        <div class="md:w-1/2 w-full py-10 px-8 bg-white flex flex-col justify-center">
            <FrontpageHeadline level="4" class="mb-3"> Abrufschein: Login erforderlich </FrontpageHeadline>

            <p class="mb-6 text-sm text-gray-600 leading-relaxed">
                Um einen Abrufschein zu beantragen, müssen Sie eingeloggt sein. Bitte melden Sie sich an oder registrieren
                Sie sich, um fortzufahren.
            </p>

            <div class="grid gap-3">
                <FrontpageButton
                    class="w-full h-11"
                    layout="primary"
                    @click="(((layout.open = false), (layout.login = true)), (layout.authGate = false))"
                >
                    Jetzt einloggen
                </FrontpageButton>

                <NuxtLink to="/mitglied-werden#formular" class="w-full" @click="layout.authGate = false">
                    <FrontpageButton outline class="w-full h-11"> Jetzt registrieren </FrontpageButton>
                </NuxtLink>
            </div>
        </div>
    </div>
</template>

<script setup>
const { layout } = useStore();

onUnmounted(() => {
    layout.authGate = false;
});
</script>

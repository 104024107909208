<template>
  <div
    class="grow-0 sm:rounded-br-3xl shadow-lg shadow-black/5 flex flex-col px-8 sm:px-12 py-20 items-start justify-center m-auto max-w-screen-md w-full bg-white max-sm:absolute max-sm:inset-0"
  >
    <button
      aria-label="Fenster schließen"
      class="absolute size-9 bg-gray-100/70 hover:bg-gray-100 rounded-full top-4 right-4 flex text-gray-500 duration-100 sm:hidden"
      @click="layout.otkInsight = false"
    >
      <Icon name="close" class="m-auto" :size="22"></Icon>
    </button>

    <div class="flex flex-col items-center justify-center w-full">
      <div class="flex flex-row items-center justify-between p-4 sm:rounded-br-[50px] mb-5">
        <Icon name="info" class="text-primary ml-5 mr-10" :size="50"></Icon>

        <div>
          <FrontpageHeadline level="4" class="w-full mb-2"
            >OTK insight für Mitglieder</FrontpageHeadline
          >
          <div v-if="!account">
            <p class="text-primary text-sm">DIE OTK insight IST EXKLUSIV FÜR UNSERE MITGLIEDER</p>

            <p class="text-start mt-4 text-sm">
              Diese können Sie in Ihrem Profil unter
              <NuxtLink to="/account" class="text-primary hover:underline">Einstellungen</NuxtLink>
              anfordern. Setzen Sie hierzu den Regler "OTK insight für Mitglieder" auf grün und Sie
              erhalten umgehend Ihre insight per E-Mail.
            </p>

            <p class="text-start mt-4 text-sm">
              Aktuelle Konditionen können Sie als Mitglied über unseren Firmenwagen-Finder einsehen.
            </p>
          </div>
          <div v-if="account">
            <p class="text-start mt-4 text-sm">
              Die OTK insight für Mitglieder können Sie in Ihrem Profil unter
              <NuxtLink
                to="/account/einstellungen"
                class="text-primary hover:underline"
                @click="closeModal"
                >Einstellungen</NuxtLink
              >
              anfordern. Setzen Sie hierzu den Regler "OTK insight für Mitglieder" auf grün und Sie
              erhalten umgehend Ihre insight per E-Mail.
            </p>

            <p class="text-start mt-4 text-sm">
              Aktuelle Konditionen können Sie als Mitglied über unseren Firmenwagen-Finder einsehen.
            </p>
          </div>
        </div>
      </div>

      <div v-if="!account" class="flex flex-wrap gap-y-2 gap-x-8 mt-2">
        <NuxtLink to="/mitglied-werden#formular">
          <FrontpageButton class="min-w-48" @click="closeModal">Mitglied werden</FrontpageButton>
        </NuxtLink>
        <FrontpageButton
          outline
          class="min-w-48 !bg-transparent hover:!bg-primary hover:!text-white hover:brightness-125 !text-primary"
          @click="
            layout.login = true;
            closeModal();
          "
        >
          Login
        </FrontpageButton>
      </div>

      <div v-else-if="account">
        <div class="flex flex-col items-center justify-center w-full mt-10"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
const { layout, account } = useStore();
const closeModal = () => {
  layout.value.otkInsight = false;
};
</script>

<template>
    <div>
        <div v-for="(category, index) in userConsentCategories" :key="index" class="mt-4 pb-2">
            <button class="flex justify-between w-full text-left text-sm focus:outline-none" @click="toggleCategory(index)">
                <span>{{ category.title }}</span>
                <Icon :name="expandedCategories.has(index) ? 'expand_less' : 'expand_more'" class="text-gray-500" />
            </button>

            <div
                class="overflow-hidden transition-all duration-300 ease-in-out"
                :class="expandedCategories.has(index) ? 'max-h-40 opacity-100' : 'max-h-0 opacity-0'"
            >
                <div class="text-sm text-gray-500 pt-2">{{ category.description }}</div>
            </div>
        </div>
    </div>

    <div class="mt-5 sm:mt-6 grid gap-4 sm:grid-cols-2">
        <FrontpageButton size="small" outline class="w-full sm:w-auto" @click="emit('reject')">
            Optionale Cookies ablehnen
        </FrontpageButton>
        <FrontpageButton size="small" class="w-full sm:w-auto" @click="emit('accept')">
            Akzeptieren & Schließen
        </FrontpageButton>
    </div>
</template>

<script setup>
const emit = defineEmits(['reject', 'accept']);

const expandedCategories = ref(new Set());
const userConsentCategories = ref([
    {
        title: 'Speicherung und Zugriff auf Informationen',
        description:
            'Cookies, Endgeräte- oder ähnliche Online-Kennungen werden verwendet, um wiederkehrende Nutzer zu erkennen und die Nutzung zu verbessern.',
    },
    {
        title: 'Standortdaten & Endgeräte-Scanning',
        description: 'Wir nutzen Technologien zur Standortbestimmung, um Ihnen passende Inhalte bereitzustellen.',
    },
    {
        title: 'Personalisierte Werbung & Analyse',
        description:
            'Wir verarbeiten Ihre Daten für personalisierte Werbung und Inhaltsanalyse, um die Benutzererfahrung zu optimieren.',
    },
]);

const toggleCategory = (index) => {
    if (expandedCategories.value.has(index)) {
        expandedCategories.value.delete(index);
    } else {
        expandedCategories.value.add(index);
    }
    expandedCategories.value = new Set(expandedCategories.value);
};
</script>
